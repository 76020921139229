.page {
}

.header {
    font-size: 30px;
    font-weight: bold;
}

.build {
    margin-top: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-body-balck);
}