.wrapper {
  position: relative;
}
.modal {
  height: calc(100vh - 0px);
  overflow-y: scroll;
  padding: 20px;
}

.modalCloseButton {
  position: absolute;
  cursor: pointer;
  z-index: 10000;
  border-radius: 99999px;
  width: 35px;
  height: 35px;
  min-width: 35px;
  top: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-grey);
  outline: none;
  &:focus,
  &:active {
    outline: none;
  }
  width: 42px;
  height: 42px;
  border-radius: 8px;
  background-color: var(--color-black-light);
  color: var(--color-white);
}

.content {
  background-color: var(--color-black-dark) !important;
}

.body {
  padding: 0px !important;
  margin: 0px !important;
}

.header {
  display: none !important;
}
