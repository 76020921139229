.button {
  transition: 0.2s all !important;
  outline: none !important;
  width: fit-content !important;
  color: var(--color-white-light) !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  height: 40px !important;
  border-radius: 9999px !important;
}

.fullWidth {
  font-size: 16px !important;
  height: 46px !important;
  border-radius: 10px !important;
  width: 100% !important;
  max-width: none !important;
}

.black {
  background-color: var(--color-black) !important;
  color: var(--color-black-light) !important;
  &:hover {
    background-color: var(--color-black) !important;
  }
}

.dark {
  background-color: var(--color-black-dark) !important;
  color: var(--color-white) !important;
  & svg {
    fill: var(--color-grey-dark) !important;
  }
  &:hover {
    background-color: var(--color-black-dark) !important;
  }
  &:disabled {
    background-color: var(--color-black-dark) !important;
    color: var(--color-grey-dark) !important;
    cursor: not-allowed !important;
    & svg {
      fill: var(--color-grey-dark) !important;
    }
  }
}

.grey {
  background-color: var(--color-black-light) !important;
  & svg {
    fill: var(--color-grey-dark) !important;
  }
}

.primal {
  background-color: var(--color-primal) !important;
  & svg {
    fill: var(--color-white-light) !important;
  }
  &:disabled {
    color: var(--color-white-light) !important;
    background-color: var(--color-primal-light) !important;
    cursor: not-allowed !important;
    & svg {
      fill: var(--color-white-light) !important;
    }
  }
}

.green {
  color: var(--color-green) !important;
  background-color: var(--color-black-light) !important;
  & svg {
    fill: var(--color-grey-dark) !important;
  }
}

.without {
  font-size: 18px !important;
  font-weight: 450 !important;
  color: var(--color-primal) !important;
  background-color: #00000000 !important;
  &:disabled {
    color: var(--color-primal-light) !important;
    background-color: #00000000 !important;
    cursor: not-allowed !important;
  }
}

.gradient {
  border: none !important;
  background: linear-gradient(
    90deg,
    var(--color-blue) 0%,
    var(--color-gradient) 50%,
    var(--color-gradient) 60%,
    var(--color-white) 100%
  ) !important;
  padding: 1px !important;
  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.8 !important;
  }
}

.large {
  height: 46px !important;
}

.normal {
  height: 40px !important;
}

.small {
  font-weight: 600 !important;
  font-size: 12px !important;
  border-radius: 7px !important;
  height: 30px !important;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
