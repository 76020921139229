.wrapper {
  position: relative;
}

.modalContent {
  margin-top: 100px !important;
  width: fit-content !important;
  background-color: var(--color-no) !important;
  overflow: hidden !important;
}

.modalBody {
  width: calc(100% - 50px) !important;
  margin-right: 46px !important;
  max-height: calc(100vh - 120px) !important;
  padding: 0 !important;
  border-radius: 20px !important;
  background-color: var(--color-black-light) !important;
}

.modalHeader {
  display: none !important;
  background-color: var(--color-no) !important;
}

.size {
  width: 100% !important;
}

.modal {
  border-radius: 20px;
  width: 100%;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

.defaultClose {
display: none !important;
}

.content {
  overflow: hidden !important;
  border-radius: 20px !important;
}

.modalCloseButton {
  position: absolute;
  cursor: pointer;
  z-index: 10000;
  border-radius: 99999px;
  background-color: var(--color-black-light);
  width: 35px;
  height: 35px;
  min-width: 35px;
  top: 0;
  right: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-grey);
  border: var(--color-grey-dark) 1px solid;
  outline: none;
  &:focus,
  &:active {
    outline: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .centeredImage {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .wallet {
      margin-right: 20px;
    }
    /* Настройте прозрачность, если нужно */
  }
}

@media screen and (max-width: 767px) {
  .modalBody {
    width: 100% !important;
    border-radius: 10px !important; 
    max-height: calc(100vh - 120px) !important;
  }
  .modalCloseButton {
    top: -50px;
    right: 0px;
  }
  .modalContent {
    margin-top: -20px !important;
  }

  .modalHeader {
    display: block !important;
    background-color: var(--color-no) !important;
  }

  .modal {
    border-radius: 10px;
    width: 100%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    display: flex;
    justify-content: center;
  }
}
