.header {
  margin: 0 auto;
  margin-top: 28px;
  height: 80px;
  background-color: var(--color-black-dark);
  border-radius: 15px;
  max-width: 1190px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.button {
  width: fit-content !important;
}

.enter {
  display: flex;

  & > :nth-child(1) {
    margin-right: 10px;
  }
}

.colorItem {
  color: var(--color-primal);
}

.nextConference {
  cursor: pointer;
  font-weight: 600;
  color: transparent;
  background: linear-gradient(
    90deg,
    var(--color-blue) 0%,
    var(--color-gradient) 70%,
    var(--color-white) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
}

.list {
  padding: 4px 14px;

  &:hover {
    background-color: #49494c !important;
  }
}

.titleHeader {
  display: flex;
  align-items: center;
  & > :nth-child(1n) {
    margin-right: 40px;
  }
}
