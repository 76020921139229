.page {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.sectionTop {
  position: relative;
  width: 100%;
  height: 493px;
  padding: 0 20px;
  overflow: hidden;
}

.fullWidthImage {
  position: absolute;
  top: 90px;
  left: 0px;
  width: 100%;
  height: 90%;
  background-image: url('../../../public/images//vector.png');
  background-size: cover;
  background-position: center;
  z-index: 0;
  opacity: 0.3;
  /* Настройте прозрачность, если нужно */
}

.gradient {
  height: 70vh;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(to bottom, var(--color-black), rgba(0, 0, 0, 0));
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .page {
    margin-top: 80px;
  }
  .sectionTop {
    height: fit-content;
  }
}

@media screen and (max-width: 767px) {
  .page {
    margin-top: 60px;
  }
  .sectionTop {
    padding: 7px;
    height: fit-content;
  }
  .fullWidthImage {
    opacity: 0.6;
  }
}
