.blockTable {
  margin-top: 22px;
  .sectionTop {
    display: flex;
    .search {
      width: 100%;
      max-width: 627px;
    }
    .checked {
      background-color: var(--color-black-light);
      width: 100%;
      height: 40px;
      border-radius: 10px;
      max-width: 232px;
      margin-left: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .table {
    margin-top: 22px;
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    text-align: left;

    th,
    td {
      min-width: 130px;
      max-width: 130px;
      padding: 10px 16px; /* Убедимся, что padding одинаков */
      vertical-align: middle; /* Центровка содержимого по вертикали */
    }

    th {
      color: var(--color-grey);
      font-weight: 450;
    }

    td {
      border-top: 1px solid var(--color-black-light);
    }
  }
}

.cellContent {
  display: flex;
  align-items: center;
  height: 56px;
  width: 100%;
  border: none; /* Убираем красную рамку для чистоты */
}

.icon {
  width: 36px;
  height: 36px;
  margin-right: 13px;
  border-radius: 9999px;
  background-color: #ffffff26;
}

.boxNames {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Добавляем для центровки */
  .name {
    color: var(--color-grey);
  }
}

.typeCoin {
  display: flex;
  align-items: center; /* Центруем иконку и текст */
}

.options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .colorProcent {
    padding: 2px 8px;
    border-radius: 5px;
    color: var(--color-red);
    background-color: var(--color-red-dark);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .colorGreen {
    color: var(--color-green);
    background-color: var(--color-green-dark);
  }

  .option {
    cursor: pointer;
    width: 40px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-black-light);

    & > :nth-child(1n) {
      width: 4px;
      height: 4px;
      border-radius: 9999px;
      background-color: #fff;
      margin: 1px;
    }
  }
}

@media screen and (max-width: 767px) {
  .blockTable {
    margin-top: 22px;
    .sectionTop {
      display: flex;
      flex-direction: column;

      .search {
        width: 100%;
        max-width: 627px;
      }
      .checked {
        margin-top: 10px;
        margin-left: 0px;
      }
    }

  }
  .tableWrapper {
    overflow-x: scroll;
    width: 100%;
  }

  .table {
    min-width: 600px;
    display: block;
  }
}
