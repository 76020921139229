.wrapper {
  margin-top: 10px;
}

.header {
  margin-bottom: 20px;
}

.settings {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header {
    max-width: 400px;
    width: 100%;
  }

  .text {
    font-weight: 300;
    color: var(--color-grey);
    margin-bottom: 10px;
  }
}

.button {
  width: 135px !important;
}

.all {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.email {
  display: flex;
  align-items: center;
  color: var(--color-grey);
  & > :nth-child(1) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1260px) {
  .blocksInfo {
    & > :not(:last-child) {
      border-bottom: 1px var(--color-black-light) solid;
    }
  }

  .settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
  }

  .email {
    margin-bottom: 20px;
  }

  .emailSettings  {
    padding-bottom: 15px;
    border-bottom: 1px solid var(--color-black-light);
    margin-bottom: 15px;
  }

  .button {
    margin-bottom: 15px;
  }
}
