.icon {
  top: 20px !important;
  left: 14px !important;
  width: 37px !important;
  height: 37px !important;
  border: var(--color-black-light) 1px solid !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: 0.2s border;
}

.input {
  border: none !important;
  background-color: var(--color-black-dark) !important;
  color: #eaeaea !important; /* Цвет текста светло-серый для хорошей читаемости */
  border-radius: 8px !important;
  width: 100% !important;
  max-width: 320px !important;
  &:focus-within {
    outline: none !important;
  }

  &::placeholder {
    color: var(--color-grey) !important; /* Цвет плейсхолдера сероватый */
  }
}

.large {
  font-size: 20px !important;
  font-weight: 600 !important;
  &::placeholder {
    font-size: 20px !important;
    font-weight: 600 !important;
  }
}

.normal {
  height: 75px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  &::placeholder {
    font-weight: 500 !important;
    font-size: 14px !important;
  }
  &_label {
    font-weight: 600 !important;
    font-size: 17px !important;
    margin-bottom: 10px !important;
  }
}

.small {
  min-height: 50px !important;
  height: 50px !important;
  font-weight: 450 !important;
  font-size: 14px !important;
  &::placeholder {
    font-weight: 450 !important;
    font-size: 14px !important;
  }
  &_label {
    color: var(--color-grey);
    font-weight: 450 !important;
    font-size: 14px !important;
    margin-bottom: 3px !important;
  }
}

.leftIcon {
  padding-left: 64px !important;
}

.fullWidth {
  width: 100%;
  max-width: none !important;
}

.border {
  border:1px solid var(--color-black-light) !important ;
}

.inputWrapper {
  &:focus-within {
    /* Активируется, если фокус находится на input */
    .icon {
      border: var(--color-primal) 1px solid !important;
      color: var(--color-white) !important;
    }
  }
}

.error {
  border: var(--color-red) solid 1px !important;
}

.errorInputWrapper {
  &.inputWrapper {
    .icon {
      color: var(--color-red) !important;
    }
    &:focus-within {
      .icon {
        border: var(--color-red) solid 1px !important;
        color: var(--color-red) !important;
      }
    }
  }
}

.errorMessage {
  margin-top: 7px;
  color: var(--color-red);
}
