.wrapper {
  max-width: 491px;
  height: fit-content;
}

.form {
  padding: 18px 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header {
  margin-top: 15px;
  font-weight: 700;
  text-align: center;
}

.head {
  margin-top: 15px;
  display: flex;
  text-align: center;
  color: var(--color-grey);
}

.inputs {
  width: 100%;
  max-width: 449px;
  margin-top: 15px;
  & > :nth-child(1n) {
    margin-bottom: 20px;
  }
}

.submit {
  width: 100%;
  max-width: 449px;
}

@media screen and (max-width: 767px) {
  .wrapper {
    min-width: 270px;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  .head {
    width: 100%;
    display: flex;
    text-align: center;
    word-break: normal;
  }
}
