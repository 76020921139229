.wrapper {
  position: relative;
}

.Container {
  position: relative;
  display: flex;
  align-items: center;
}

.Container > :nth-child(1n) {
  margin-right: 8px;
}

.PinField {
  width: 51px;
  height: 51px;
  font-size: 22px;
  line-height: 25px;
  color: var(--color-white);
  background-color: var(--color-black-dark);
  border: var(--color-grey-dark) solid 1px;
  text-align: center;
  transition-duration: 20ms;
  border-radius: 8px;
  outline: none;
  color: var(--color-white);
  caret-color: var(--color-grey);
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.error {
  border: var(--color-red) solid 1px;
}

.two > :nth-child(2n) {
  margin-right: 13px;
}

.dashed {
  width: 8px;
  height: 2px;
  background-color: var(--color-white);
}

.dashedOne {
  position: absolute;
  top: 19px;
  left: 61px;
}

.dashedTwo {
  position: absolute;
  top: 19px;
  left: 131px;
}

@media screen and (max-width: 767px) {
  .PinField {
    width: 40px;
    height: 40px;
  }
  .Container > :nth-child(1n) {
    margin-right: 2px;
  }
}
