.wrapper {
  margin-top: 50px;
}

.form {
  margin-top: 15px;
  & > :nth-child(1) {
    margin-bottom: 10px;
  }
}

.buttons {
  margin-top: 25px;
  display: flex;
  & > :nth-child(1) {
    margin-right: 12px;
  }
}

.content {
  min-height: 286px;
  width: 100%;
  max-width: 415px;
  display: flex;
  flex-direction: column;
}

.header {
  width: fit-content;
  color: transparent;
  background: linear-gradient(
    90deg,
    var(--color-blue) 0%,
    var(--color-gradient) 70%,
    var(--color-white) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 600;
  user-select: none;
}

.headerSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: transparent;
  background: linear-gradient(
    90deg,
    var(--color-blue) 0%,
    var(--color-gradient) 70%,
    var(--color-white) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 600;
  user-select: none;
}

.checked {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 13px;
}
