.modal {
  padding: 32px 27px;
  width: 371px;
}

.image {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 110%;
    height: 110%;
    object-fit: cover;
  }
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
  color: var(--color-grey);
}

.btn {
  margin-top: 30px;
}
