.wrapper {
  height: 100vh;
  min-width: 633px;
  overflow: auto;
  background-color: var(--color-black-dark);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .wrapper {
    height: 100vh;
    min-width: 460px;
    padding: 50px;
    width: fit-content;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    height: 100vh;
    min-width: 200px;
    padding: 14px;
    width: 100%;
  }
}
