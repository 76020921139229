.form {
  width: 100%;
  margin-top: 25px;
}

.buttons {
  display: flex;
  margin-top: 17px;
  .next {
    width: 130% !important;
    margin-right: 12px;
  }
}

.successMessage {
  margin-top: 50px;
  .header {
    font-weight: 600;
    margin-bottom: 77px;
  }
}