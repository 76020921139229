.root {
  width: 64px !important;
  height: 28px !important;
}

.track {
  width: 64px !important;
  height: 28px !important;
  transition: background-color 0.3s ease !important;
}

.thumb {
  width: 20px !important;
  height: 20px !important;
  background-color: var(--color-white-light) !important;
}

.switch:checked + .track {
  background-color: var(--color-grey-ligh) !important;
}

.switch:checked + .track .thumb {
  margin-left: -8px;
  background-color: var(--color-white-light) !important;
}

.switch:checked + .track {
  border: var(--color-primal) solid 1px !important;
}


.switch {
  &:checked {
    & + .track {
      background-color: var(--color-primal) !important;
    }
  }
}
