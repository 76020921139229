.wrapper {
  background-color: var(--color-black-dark);
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.logo {
  width: fit-content;
  margin-top: 10px;
}

.enter {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primal);
  border-radius: 8px;
  cursor: pointer;
  &.darkBlock {
    background-color: var(--color-black-light);
  }
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
}

@media screen and (max-width: 767px) {
  .wrapper {
    height: 60px;
  }
}
