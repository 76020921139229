.blockWallet {
  padding: 0;

  .rowMany {
    min-height: 90px;
    border-bottom: var(--color-black-light) solid 1px;
    display: flex;
    justify-content: space-between;
    padding: 18px 40px;
    & > :nth-child(1n) {
      min-width: 100px;
    }
    .head {
      color: var(--color-grey);
      margin-bottom: 7px;
    }
  }
}
.rowDetail {
  display: flex;
  height: 100%;
  width: 100%;
  & > :not(:last-child) {
    border-right: var(--color-black-light) solid 1px;
  }
  & > :nth-child(1n) {
    & > :nth-child(1) {
      min-width: 20px;
      margin-right: 10px;
    }
    padding: 10px;
    min-height: 73px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s color ease;
    &:hover {
      color: var(--color-primal);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .blockWallet {
    .rowMany {
      flex-wrap: wrap;
      & > :nth-child(1n) {
        padding: 20px 0;
      }
    }
  }


}

@media screen and (max-width: 767px) {
  .blockWallet {
    .rowMany {
      flex-wrap: wrap;
      & > :nth-child(1n) {
        min-width: 120px;
        padding: 20px;
      }
    }
  }
  .rowDetail {
    flex-direction: column;
    & > :nth-child(1n) {
      justify-content: flex-start;
      margin-left: 30px;
    }
  }
}
