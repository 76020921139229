.wrapper {
  height: 100vh;
  overflow: auto;
  min-width: 633px;
  background-color: var(--color-black-dark);
  display: flex;
  justify-content: center;
  align-items: center;
}


.content {
  height: 286px;
  width: 100%;
  max-width: 415px;
  display: flex;
  flex-direction: column;
}

.header {
  width: fit-content;
  color: transparent;
  background: linear-gradient(
    90deg,
    var(--color-blue) 0%,
    var(--color-gradient) 70%,
    var(--color-white) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 600;
  user-select: none;
}


@media screen and (min-width: 768px) and (max-width: 1260px) {
  .wrapper {
    height: 100vh;
    min-width: 460px;
    padding: 50px;
    width: fit-content;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    height: 100vh;
    min-width: 200px;
    padding: 14px;
    width: 100%;
  }
}

