.wrapper {
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1230px;
  padding: 30px;
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(333px, 1fr));
  width: 100%;
  justify-content: center;
  gap: 30px;
}

.card {
  cursor: pointer;
  background-color: #525252;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  height: 283px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 105%;
    height: 105%;
    object-fit: cover;
  }
  position: relative;
}

.content {
  padding: 30px;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.head {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding: 10px;
  }
  .card {
    & > img {
      width: 120%;
      height: 120%;
    }
  }
  .content {
    padding: 10px;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }

  .head {
    display: flex;
    flex-wrap: wrap;
    & > * {
      font-size: 25px !important;
    }
  }
}
