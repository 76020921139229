.wrapper {
  height: 100vh;
  min-width: 633px;
  overflow: auto;
  background-color: var(--color-black-dark);
  display: flex;
  justify-content: center;
}

.content {
  width: 100%;
  max-width: 415px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-top: 90px;
  color: transparent;
  background: linear-gradient(
    90deg,
    var(--color-blue) 0%,
    var(--color-gradient) 70%,
    var(--color-white) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: 600;
}

.enter {
  margin-top: 22px;
  display: flex;
  align-items: center;
}

.notif {
  margin-top: 20px;

  display: flex;
  .warn {
    min-width: 17px;
    margin-right: 20px;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    color: var(--color-primal);
  }
  .block {
    color: var(--color-grey);
  }
}

.form {
  width: 100%;
  margin-top: 20px;
}

.message {
  margin-top: 20px;
  display: flex;
  text-align: center;
  max-width: 300px;
}

.options {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  & > :nth-child(2) {
    margin-top: 8px;
  }
}

.login {
  margin-top: 10px;
  & > :nth-child(2) {
    margin-top: 8px;
  }
}

.inputs {
  & > :nth-child(2) {
    margin-top: 18px;
  }
}

.tech {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .svgTelegramm {
    transition: 0.2s all;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: var(--color-primal-light);
    margin-right: 10px;
    border-radius: 9999px;
  }
}

.home {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  margin-top: -6px;
  margin-right: -10px;
  color: var(--color-grey);
  transform: 0.3s color;
  &:hover {
    color: var(--color-primal);
  }
}

.linkTech {
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  transition: 0.2s all;
  &:hover {
    color: var(--color-primal);
    .svgTelegramm {
      background-color: var(--color-primal);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .wrapper {
    height: 100vh;
    min-width: 460px;
    padding: 50px;
    width: fit-content;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    height: 100vh;
    min-width: 200px;
    padding: 14px;
    width: 100%;
  }
}
