.options {
  margin-top: 29px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: center;
    color: var(--color-grey);
  }
  .balance {
    margin-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .totalBalance {
      display: flex;
      align-items: center;
    }
    .buttonsControl {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      & > :nth-child(1n) {
        background-color: var(--color-black-light);
        border-radius: 9999px;
        height: 27px;
        width: 27px;
        cursor: pointer;
        margin: 0 7px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .add {
        background-color: var(--color-primal);
      }
    }
  }
}
