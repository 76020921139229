.body {
  max-width: fit-content !important;
}

.labelChecked {
  transition: 0.2s color !important;
  color: var(--color-grey-dark) !important;
  &:hover {
    color: var(--color-primal) !important;
  }
}

.input {
  cursor: pointer !important;
  outline: none !important;

  width: 20px !important;
  height: 20px !important;
  border-radius: 5px !important;

  &.checked {
    background-color: var(--color-primal) !important;
  }

  &.checked::after {
    display: block !important;
  }
}

.label {
  cursor: pointer !important;
  user-select: none !important;
}

.dark {
  &_input {
    background-color: var(--color-black-dark) !important;
    border: 1px solid var(--color-grey-dark) !important;
  }
  &_label {
    color: var(--color-grey) !important;
  }
}

.white {
  &_input {
    background-color: var(--color-black-ligh) !important;
    border: 1px solid var(--color-grey-dark) !important;
  }
  &_label {
    color: var(--color-white) !important;
  }
}
