.input {
  border: 1px var(--color-black-dark) solid !important;
  background-color: var(--color-black-dark) !important;
  color: var(--color-white) !important;
  padding: 9px !important;
}

.borderError {
  border-color: var(--color-red) !important;
}

.label {
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  color: var(--color-grey);
  margin-bottom: 5px;
}

.errorMessage {
  margin-top: 3px;
  color: var(--color-red);
}
