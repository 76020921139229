.modal {
  padding: 32px 27px;
  width: 579px;
  min-height: 400px;
}

.title {
  margin-bottom: 23px;
}

.block {
  padding: 22px 23px;
  margin-bottom: 5px;
}

.row {
  display: flex;
  justify-content: space-between;
  .from {
    color: var(--color-grey);
  }
  .count {
    display: flex;
    .counter {
      color: var(--color-grey);
      margin-right: 10px;
    }
    .max {
      color: var(--color-primal);
    }
  }
}
.item {
  display: flex;
  align-items: center;
  color: var(--color-white) !important;
  & > :nth-child(2) {
    margin-left: 2px;
  }
}

.pinInput {
  height: 38px !important;
  width: 38px !important;
  font-size: 16px;
  font-weight: 600;
}

.paste {
  cursor: pointer;
}

.countInput {
  width: 100%;
  margin-left: -10px;
  margin-right: 20px;
}

.balance {
  margin-top: 14px;
  .count {
    color: var(--color-grey);
  }
}

.password {
  margin-top: 15px;
}

.info {
  margin-top: 15px;
  .options {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      color: var(--color-grey);
    }
    .about {
      display: flex;
      flex-direction: column;

      & > :nth-child(2) {
        display: flex;
        justify-content: flex-end;
        color: var(--color-grey);
      }
    }
  }
}

.infoAddress {
  color: var(--color-grey);
}

.send {
  margin-top: 15px;
  height: 60px;
  .contenBtn {
    display: flex;
    align-items: center;
    .contentSVG {
      width: 60px;
    }
  }
}

.blockError {
  border: var(--color-red) solid 1px !important;
}
