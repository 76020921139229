.input {
  background-color: var(--color-black-light) !important;
  color: #eaeaea !important;
  height: 40px !important;
  border-radius: 10px !important;
  width: 100% !important;
  border: none !important;
  &:focus-within {
    outline: none !important;
  }

  &::placeholder {
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 450 !important;
    color: var(--color-grey) !important;
  }
}

.icon {
  margin-left: 25px;
}

.leftIcon {
  padding-left: 68px !important;
}

.label {
  font-weight: 600 !important;
  font-size: 17px !important;
  margin-bottom: 10px !important;
}

.fullWidth {
  width: 100% !important;
  max-width: none !important;
}

.error {
  border: var(--color-red) solid 1px !important;
}

.errorInputWrapper {
  &.inputWrapper {
    .icon {
      color: var(--color-red) !important;
    }
    &:focus-within {
      .icon {
        border: var(--color-red) solid 1px !important;
        color: var(--color-red) !important;
      }
    }
  }
}

.errorMessage {
  margin-top: 7px;
  color: var(--color-red);
}
