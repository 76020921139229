.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 76px;
  border-bottom: var(--color-black-light) 1px solid;
  padding-left: 60px;
  z-index: 1000;
}

.logout {
  font-size: 14px;
  height: 50px;
  width: 120px;
  border-radius: 99999px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600px;
  color: var(--color-primal);
  &:hover {
    color: var(--color-primal-light);
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  & > :nth-child(1n) {
    margin-right: 40px;
  }
}

.link {
  width: fit-content;
  font-weight: 600;
  transition: 0.2s color;
  color: var(--color-black-light);
}

.active {
  color: var(--color-white-light);
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .header {
    margin-top: 80px;
    padding-left: 30px;
    height: 60px;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    & > :nth-child(1n) {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    margin-top: 60px;
    padding-left: 15px;
    height: 40px;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    & > :nth-child(1n) {
      margin-right: 12px;
    }
  }
}