.wrapper {
  background-color: var(--color-black);
  width: 100%;
  display: flex;
  position: relative;
  cursor: default;
  height: 100vh;
}

.form {
  position: relative;
  width: fit-content;
}


.header {
  display: none;
  padding-top: 18px;
  padding-left: 20px;
  padding-bottom: 10px;
  position: absolute;
  z-index: 1000;
}
@media screen and (max-width: 767px) {
  .form {
    width: 100%;
  }
  .header {
    width: 100%;
    display: block;
    background-color: var(--color-black-dark);
  }
  .logo {
    width: 100%;
  }
}
