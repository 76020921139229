.wrapper {
  width: 458px;
  height: fit-content;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 18px 10px;
}

.header {
  font-weight: 700;
  text-align: center;
}

.head {
  margin-top: 10px;
  display: flex;
  text-align: center;
  color: var(--color-grey);
}

.inputs {
  width: 100%;
  margin-top: 15px;
  & > :nth-child(1n) {
    margin-bottom: 5px;
  }
}

.submit {
  margin-top: 15px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .form {
    padding: 0px;
  }
}