.side {
  width: 300px;
  min-width: 300px;
  background-color: var(--color-black-dark);
  border-radius: 29px;
  min-height: fit-content;
  max-height: calc(100vh - 20px);
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
}

.menu {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  min-height: 20px;
  flex-grow: 1;
  height: fit-content;
  overflow-y: auto;
  max-height: calc(100% - 45px);
}

.link {
  position: relative;
  cursor: pointer;
  height: 33px;
  width: 100%;
  transition: 0.2s color;
  font-weight: 600;
  display: flex;
  align-items: center;
  &:hover {
    & > :nth-child(1) {
      color: var(--color-primal-light);
    }
    svg {
      path {
        stroke: var(--color-primal-light);
      }
    }
  }
  svg {
    margin-right: 10px;
    path {
      stroke: white;
    }
  }
}

.active {
  & > :nth-child(1) {
    color: var(--color-primal);
  }
  svg {
    path {
      stroke: var(--color-primal);
    }
  }
  &:hover {
    color: var(--color-white);
    & > :nth-child(1) {
      color: var(--color-primal);
    }
    svg {
      path {
        stroke: var(--color-primal);
      }
    }
  }
}

.token {
  margin-top: 10px;
  cursor: pointer;
  background-color: var(--color-black-light);
  border-radius: 99999px;
  height: 25px;
  color: var(--color-grey);
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-weight: 450;
  .text {
    width: 100%;
    overflow: hidden;
    margin-right: 40px;
  }
}

.textCopy {
  svg path {
    stroke: var(--color-black-light);
  }
}

.fee {
  cursor: default;
  position: absolute;
  right: 0px;
  width: fit-content;
  background-color: var(--color-black-light);
  border-radius: 8px;
  padding: 4px 10px;
  font-weight: 450px;
  color: var(--color-grey);
}
