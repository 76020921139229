.content {
  margin-top: 20px;
  .row {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    & > :nth-child(1) {
      width: 100%;
      max-width: 234px;
      min-width: 234px;
    }
    & > :nth-child(3) {
      width: 150px;
    }
    & > :nth-child(4) {
      width: fit-content;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    .column {
      display: flex;
      flex-direction: column;

      .settings {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        h5 {
          font-weight: 300;
          color: var(--color-grey);
        }

        .cell {
          overflow: hidden;
          word-break: break-all;
        }
      }
    }
  }
}

.listInfo {
  margin-bottom: 20px;
  padding-right: 20px;
}

.wrapperList {
  display: flex;
}

@media screen and (max-width: 1260px) {
  .content {
    .row {
      flex-direction: column;
      & > :nth-child(1) {
        width: 100%;
        max-width: none;
        min-width: none;
      }
    }
  }
}


