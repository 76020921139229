// Определение переменных размеров
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 24px;

// Заголовки
$heading-h1: 30px;
$heading-h2: 28px;
$heading-h3: 24px;
$heading-h4: 20px;
$heading-h5: 15px;
$heading-h6: 10px;

// Глобальные стили текста
body {
  font-size: $font-size-md;
  line-height: 1.5;
}

// Стили для заголовков
h1 {
  font-size: $heading-h1;
  line-height: 1.2;
}

h2 {
  font-size: $heading-h2;
  line-height: 1.3;
}

h3 {
  font-size: $heading-h3;
  line-height: 1.4;
}

h4 {
  font-size: $heading-h4;
  line-height: 1.5;
}

h5 {
  font-size: $heading-h5;
  line-height: 1.6;
}

h6 {
  font-size: $heading-h6;
  line-height: 1.7;
}
