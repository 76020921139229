.layout {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  overflow: hidden;
}

.wrapper {
  width: 100%;
  position: relative;
}

.page {
  width: 100%;
  margin-right: 20px;
  padding: 60px;
  overflow-y: auto;
  max-height: calc(100vh - 84px);
}

.content {
  max-width: 924px;
  overflow: hidden;
}

.logout {
  font-size: 14px;
  height: 50px;
  width: 70px;
  border-radius: 99999px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600px;
  color: var(--color-primal);
  &:hover {
    color: var(--color-primal-light);
  }
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .page {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  .page {
    padding: 14px;
  }
}
