.item {
  display: flex;
  align-items: center;
  & > svg {
    width: 27px;
  }
  & > :nth-child(2) {
    margin-left: 5px;
    color: var(--color-white) !important;
  }
}
