.wrapper {
  margin: 50px auto;
  position: relative;
  z-index: 2;
  width: 652px;
}

.buttons {
  height: 35px;
  display: flex;
  & > :nth-child(1n) {
    margin-right: 24px;
  }
}

.text {
  margin-top: 29px;
  & > :nth-child(1n) {
    line-height: 41px;
  }
}

.buttonNext {
  background-color: white !important;
  height: 35px !important;
  & > :nth-child(1) {
    background-color: var(--color-black) !important;
    padding: 10px 16px !important;
    border-radius: 99999px !important;
  }
}

.next {
  display: flex;
  margin-top: 29px;
  height: 54px;
  width: 100%;
  .regist {
    height: 54px !important;
    font-size: 20px !important;
  }
  .telegram {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    width: 54px;
    height: 54px;
    border-radius: 99999px;
    background-color: var(--color-primal);
    & > :nth-child(1) {
      margin-left: -3px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
    padding: 0;
    margin: 0px;
    margin-top: 50px;

  }
  .buttons {
    height: fit-content;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    & > :nth-child(1n) {
      margin: 0;
    }
  }
  .buttonNext {
    margin: 10px 8px;
    & > :nth-child(1) {
      width: 135px !important;
    }
  }
  .text {
    display: flex;
    text-align: center;
    flex-direction: column;
    padding: 7px;
  }
  .next {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .regist {
      height: 44px !important;
    }
    .telegram {
      width: 44px;
      height: 44px;
      min-width: 44px;
      max-width: 44px;
    }
  }
}
