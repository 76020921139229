.wrapper {
  margin: 0px auto;
  margin-top: 40px;
  max-width: 1005px;
  display: flex;
  justify-content: center;
}

.swiper {
  width: 100%;
  max-width: 875px;
  height: 360px;
  :global(.swiper-pagination-bullet) {
    background-color: var(--color-grey-dark);
    height: 8px;
  }
  :global(.swiper-pagination-bullet-active) {
    background-color: var(--color-white-light);
  }
}

.button {
  height: 42px;
  min-width: 42px;
  border-radius: 9999px;
  cursor: pointer;
  background-color: var(--color-primal);
  margin: 30px;
  margin-top: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.card {
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--color-black-full);
  min-height: 320px;
  width: 100%;
  display: flex;
  padding: 30px;
}

.textInImage {
  display: flex;
  align-items: center;
  text-align: left;
  position: absolute;
  z-index: 1;
  font-size: 33px;
  left: 66px;
  top: 43px;
  font-style: normal;
  font-weight: 700;
  line-height: 94%;
}


.customText {
  display: flex;
  align-items: center;
  text-align: left;
  position: absolute;
  font-family: 'NAURYZREDKEDS';
  z-index: 1;
  left: 40px;
  top: 43px;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 94%;
}

.title {
  margin-top: 15px;
  display: flex;
  text-align: center;
  font-weight: 450;
  width: 100%;
  min-width: 223px;
  & > * {
    line-height: 26px;
  }
}

.buttonPrev {
  height: 46px !important;
  border-radius: 20px !important;
}

.buttonNext {
  background-color: white !important;
  height: 35px !important;
  & > :nth-child(1) {
    background-color: var(--color-black) !important;
    padding: 10px 16px !important;
    border-radius: 99999px !important;
  }
}

.image {
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #525252;
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.block {
  width: 283px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .box {
    padding: 20px;
  }

  .card {
    padding: 20px;
  }

  .swiper {
    min-height: 380px;
  }

  .buttonPrev {
    margin-top: 30px;
  }

  .customText {

    left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .textInImage {
    left: 20px;
    font-size: 23px;
    padding-right: 30px;
  }
  .buttonNext {
    margin-top: 30px;
  }
  .customText {
    font-size: 25px;
    left: 20px;
  }
  .card {
    flex-direction: column;
    padding: 10px;
    height: 500px;
  }
  .box {
    padding: 10px;
  }

  .buttonPrev {
    margin-top: 30px;
  }

  .image {
    width: 100%;
    max-width: none;
    height: 200px;
  }

  .swiper {
    height: 570px;
  }

  .block {
    height: 100%;
    max-height: 280px;
    margin: 0 auto;
    width: 100%;
    max-width: 440px;
  }
}
