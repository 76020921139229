.modal {
  width: 590px;
  min-height: 681px;
}

.updateQR {
  cursor: pointer;
}

.subTitle {
  margin-top: 15px;
  display: flex;
  text-align: center;
  color: var(--color-grey);
}

.btnUpdate {
  margin-top: 20px;
}

.keyHeader {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  color: var(--color-grey);
}

.pin {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-red);
}

.key {
  cursor: pointer;
  margin-top: 15px;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  transition: 0.2s color;
  overflow: hidden;
  &:hover {
    color: var(--color-primal-light);
  }
}

.image {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto;
  width: 150px;
  height: 150px;
  overflow: hidden;
  & > img {
    width: 110%;
    height: 110%;
    object-fit: cover;
  }
}

.text {
  margin-top: 15px;
}

.importBtn {
  margin-top: 15px;
}

.loader {
  margin-top: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.copy {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.check {
  color: var(--color-green);
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 30px;
  color: var(--color-primal);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.keyText {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .modal {
    width: 100%;
  }
  .importBtn {
    height: 100px;
  }

  .keyText {
    display: flex;
    text-align: center;
    word-break: break-all;
  }
}
