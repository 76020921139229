@import './vars.scss';
@import './reset.scss';
@import './fonts.scss';
@import './_mantine.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-black); // Фон (основной тёмный цвет)
  color: var(--color-white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  // background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #eaeaea !important;
  caret-color: #eaeaea !important;
  -webkit-box-shadow: 0 0 0px 1000px var(--color-black-dark) inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* Для Firefox */
input:-moz-autofill {
  box-shadow: 0 0 0px 1000px var(--color-black-dark) inset !important;
  color: #eaeaea !important;
  caret-color: #eaeaea !important;
}

/* Для других браузеров (общий селектор) */
input:autofill {
  box-shadow: 0 0 0px 1000px var(--color-black-dark) inset !important;
  color: #eaeaea !important;
  caret-color: #eaeaea !important;
}

/* Кастомный скролл для WebKit-браузеров (Chrome, Safari, Edge) */
* {
  scrollbar-width: thin;
  scrollbar-color: #b78bf7 transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #b78bf7;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #6458d0;
}

/* Для Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #b78bf7 transparent;
}

input {
  /* Убираем стили браузерных автозаполнений */
  background-color: transparent;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px inherit inset !important;
}
