:root {
  --color-black: #111112;
  --color-black-light: #33333a;
  --color-black-dark: #24242b;
  --color-black-full: #18181a;

  --color-primal-dark: #7569f0;
  --color-primal-light: #c8b1e9;
  --color-primal: #b78bf7;

  --color-gradient: #a28af8;
  --color-blue: #00c0ff;

  --color-white: #ffffff;
  --color-white-light: #f5f5f5;
  --color-white-dark: #828282;

  --color-red: #fd5050;
  --color-red-dark: #412121;

  --color-green: #5caf5b;
  --color-green-dark: #303a31;

  --color-grey: #a3a3a3;
  --color-grey-dark: #49494c;
  --color-grey-light: #d9d9d9;

  --color-no: #00000000;

  --swiper-pagination-bullet-horizontal-gap: 17px;
}
