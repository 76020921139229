.form {
  width: 100%;
  margin-top: 25px;
}

.phone {
  margin-top: 20px;
  display: flex;
  display: flex;
  align-items: center;
  & > :nth-child(1n) {
    margin-left: 2px;
  }
}

.pin {
  width: 28px;
  height: 37px;
  font-size: 22px;
  line-height: 25px;
  color: var(--color-white);
  background-color: var(--color-black-light);
  border: var(--color-black-light) solid 1px;
  text-align: center;
  transition-duration: 20ms;
  border-radius: 4px;
  outline: none;
  color: var(--color-white);
  caret-color: var(--color-grey);
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noop {
  width: 8px;
  height: 8px;
  border-radius: 9999px;
  background-color: var(--color-white);
}

.dashed {
  width: 8px;
  height: 2px;
  background-color: var(--color-white);
}

.message {
  padding-top: 20px;
  position: relative;
}

.btn {
  display: flex;
  margin-top: 17px;
}
