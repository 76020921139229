.icon:nth-child(1) {
  &:nth-child(1) {
    transition: 0.2s border;
    top: 20px !important;
    left: 14px !important;
    width: 37px !important;
    height: 37px !important;
    border: var(--color-black-light) 1px solid !important;
    border-radius: 5px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

.input {
  background-color: var(--color-black-dark) !important;
  color: #eaeaea !important; /* Цвет текста светло-серый для хорошей читаемости */
  height: 75px !important;
  padding-right: 20px !important;
  border-radius: 8px !important;
  width: 100% !important;
  max-width: 320px !important;
  border-color: var(--color-black-light) !important ;
  cursor: pointer !important;
  &:focus-within {
    outline: none !important;
    cursor: auto !important;
  }

  &::placeholder {
    color: var(--color-grey) !important; /* Цвет плейсхолдера сероватый */
  }
}

.leftIcon {
  padding-left: 64px !important;
  padding-right: 60px !important;
}

.label {
  font-size: 12px !important;
}

.fullWidth {
  width: 100%;
  max-width: none !important;
}

.icon.rightSection {
  border: none !important; /* Отключаем любые бордеры для глазика */
}

.inputWrapper {
  cursor: pointer !important;
  &:focus-within .icon:nth-child(1):nth-child(1) {
    border: var(--color-primal) 1px solid !important;
    color: var(--color-white) !important;
  }
}

.rightSection {
  margin-right: 20px !important;
  &:nth-child(1) > span > svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.error {
  border: var(--color-red) solid 1px !important;
}

.errorInputWrapper {
  .icon:nth-child(1):nth-child(1) {
    border: var(--color-red) 1px solid !important;
    color: var(--color-red) !important;
  }
  &:focus-within .icon:nth-child(1):nth-child(1) {
    border: var(--color-red) 1px solid !important;
    color: var(--color-red) !important;
  }
}

.errorIcon {
  &:nth-child(1) > span > svg > * {
    stroke: var(--color-red) !important;
    fill: var(--color-red) !important;
    stroke-width: 0.2px !important;
  }
}

.errorMessage {
  margin-top: 7px;
  color: var(--color-red);
}

.label {
  color: var(--color-grey) !important;
  font-weight: 450 !important;
  font-size: 15px !important;
  margin-bottom: 3px !important; 
}