.navigate {
  color: var(--color-primal);
  cursor: pointer;
  width: fit-content;
  position: relative;
  display: inline-block;
  &:hover {
    width: fit-content;
    color: var(--color-primal-light);
  }
}

.navigate::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 4px;
  width: 100%;
  height: 1px;
  background-color: currentColor;
}
