.wrapper {
  width: 491px;
  height: fit-content;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 18px 12px;
}

.header {
  margin-top: 15px;
  font-weight: 700;
  text-align: center;
}

.email {
  margin-top: 16px;
  display: flex;
  text-align: center;
  color: var(--color-grey);
}

.pin {
  height: 60px;
  margin-top: 14px;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-red);
}

.againAndTime {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.sendCodeAgain {
  cursor: pointer;
  font-weight: 700;
  transition: 0.2s color;
  &:hover {
    width: fit-content;
    color: var(--color-primal-light);
  }
}

.time {
  color: var(--color-grey);
}

.changeEmail {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s color;
  &:hover {
    width: fit-content;
    color: var(--color-primal-light);
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .time {
    display: flex;
    text-align: center;
    max-width: 220px;
    margin-bottom: 30px;
  }
}
