.root {
    background-color: var(--color-card-general) !important;   
}

.label {
    height: 60px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 20px !important;
    min-width: 70px;
    color: var(--color-text-accent) !important;
    &:hover {
        color: var(--color-text-accent) !important;
    }
    &__active {
        color: var(--color-body-balck) !important;
    }
}

.active {
    background-color: none !important;
}

.icon {
    transition: color 0.1s !important; 
}

.icon__active{
    color: var(--color-body-balck) !important;
}
