.wrapper {
  margin-top: 10px;
}

.settings {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    font-weight: 300;
    margin-right: 10px;
    color: var(--color-grey);
  }
}

.button {
  width: fit-content !important;
  min-width: fit-content !important;
}

@media screen and (max-width: 1260px) {
  .settings {
    padding-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    & > :nth-child(2) {
        margin-top: 20px;
    }
  }
  .wrapper {
    margin-top: 10px;
    & > :not(:last-child) {
      border-bottom: var(--color-black-light) 1px solid;
    }
  }
}
