.block {
  cursor: default;
  border-radius: 10px;

  padding: 14px 20px;
  border: var(--color-black-dark) solid 1px;

  margin-bottom: 15px;
}

.light {
  background-color: var(--color-black-light);
  border: var(--color-black-light) solid 1px;
}

.dark {
  background-color: var(--color-black-dark);
  border: var(--color-black-light) solid 1px;
}
