.title {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none !important;
}

.arrow {
    margin-left: 15px;
    margin-top: 4px;
}


.dropdown {
    background-color: var(--color-black-light) !important;
    border: none !important;    
    padding:  0 !important;
    border-radius: 7px !important;
    overflow: hidden !important;
    user-select: none !important;
}