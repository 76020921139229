.block {
  width: fit-content;
  padding: 21px 25px;
}

.blockRow {
  display: flex;
  flex-wrap: wrap;
  & > :not(:last-child) {
    margin-right: 25px;
  }
  & > :nth-child(1n) {
    margin-bottom: 29px;
  }
}

.calendar {
  .label {
    margin-bottom: 5px;
    color: var(--color-grey);
  }

  .calendarBlock {
    cursor: pointer;
    width: 199px;
    height: 35px;
    border-radius: 5px;
    background-color: var(--color-black-light);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px;
  }
}

.blockRight {
  display: flex;
  justify-content: flex-end;
}

.clearBtn {
  display: flex;
  width: 200px;
}

.button {
  display: flex !important;
  justify-content: space-between !important;
  & > :nth-child(1) {
    margin-right: 10px;
  }
}
