.logoCoin {
    border-radius: 9999px;
    width: 30px;
    height: 30px;
    background-color: var(--color-black-light);
    & * svg {
      margin-top: -6px;
      margin-left: -4px;
      width: 20px;
    }
  }