.wrapper {
  margin-top: 40px;
  min-height: 456px;
}

.container {
  background-color: var(--color-black-full);
  min-height: 364px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 30px;
}

.bottom {
  display: flex;
  justify-content: center;
  height: 92px;
}

.content {
  display: flex;
  max-width: 1190px;
  width: 100%;
  height: 100%;
}

.blockLogo {
  min-width: 325px;
  margin-top: 30px;
  margin-right: 30px;
}

.inRussia{
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: center;
}

.contacts {
  height: 40px;
  margin-top: 64px;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px; 
  margin-bottom: 30px;
}

.circleBlock {
  width: 60px;
  height: 60px;
  background-color: var(--color-white-dark);
  border-radius: 99999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  width: 100%;
  justify-content: center;
  row-gap: 100px;

  height: 100%;
  margin-top: 30px;
}

.card {
  overflow: hidden;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-black-light);
  padding: 0 30px;
}

.head {
  font-size: 20px;
}

.body {
  margin-top: 30px;
  font-size: 16px;
  & > :nth-child(1n) {
    margin-top: 20px;
  }
}

.options {
  margin-top: 40px;
  width: 100%;
  max-width: 1190px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: var(--color-primal);
  font-weight: 400;
  flex-wrap: wrap;;
}

.block {
  width: 100%;
  margin-right: 30px;
  height: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .section {
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
  .bottom {
    height: 80px;
  }
  .row {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .options { 
    margin-bottom: 30px;
    flex-direction: column;
    & > :nth-child(1n) {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .content {
    flex-direction: column;
  }
  .section {
    margin-top: 30px;
    display: block;
  }
  .card {
    margin-top: 30px;
  }
  .bottom {
    height: 80px;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .options { 
    margin-bottom: 30px;
    flex-direction: column;
    & > :nth-child(1n) {
      margin-top: 20px;
    }
  }
}
