.wrapper {
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  cursor: pointer;
  width: 100%;
  max-width: 1190px;
  min-height: 220px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--color-black-full);
  border-radius: 20px;
  overflow: hidden;
  z-index: 10;
}

.content {
  height: 100%;
  padding: 25px;
  width: 100%;
  max-width: 457px;
  min-width: 557px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 457px;
  height: 100%;
}

.head {
  font-weight: 700;
}

.body {
  margin-top: 20px;
  font-weight: 400;
}

.lines {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.miniLine {
  position: absolute;
  width: fit-content;
  left: 200px;
}

.middleLine {
  position: absolute;
  right: 0;
  z-index: 1;
}

.button {
  height: 54px !important;
}

.registration {
  position: relative;
  height: 120px;
  display: flex;
  align-items: center;
}

.arrowMouse {
  position: absolute;
  z-index: 1;
  top: 70px;
  left: 140px;
}

.gradient {
  background: linear-gradient(
    90deg,
    var(--color-blue) 0%,
    var(--color-gradient) 50%,
    var(--color-gradient) 60%,
    var(--color-white) 100%
  );
  padding: 1px;
  border-radius: 99999px;
  width: fit-content;
  height: 40px;
  font-size: 22px;
  line-height: 32px;
  & > :nth-child(1) {
    background-color: var(--color-black);
    padding: 0px 10px;
    border-radius: 99999px;
    & > :nth-child(1n) {
      margin-top: 90px;
      font-weight: 300;
      font-style: italic;
      color: transparent;
      background: linear-gradient(
        90deg,
        var(--color-blue) 0%,
        var(--color-gradient) 70%,
        var(--color-white) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
}


@media screen and (min-width: 768px) and (max-width: 1260px) {
  .container {
    flex-direction: column;
  }
  .content {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none;
    width: 100%;
    text-align: center;
  }
  .registration {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 470px) and (max-width: 768px) {
  .wrapper {
    margin: 10px;
  }
  .container {
    flex-direction: column;
  }
  .content {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: none;
    width: 100%;
  }

  .registration {
    width: fit-content;
    margin-bottom: 40px;
  }

  .head,
  .body {
    max-width: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 469px) {
  .wrapper {
    margin: 10px;
  }
  .container {
    flex-direction: column;
  }
  .content {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: none;
    width: 100%;
  }
  .registration {
    width: fit-content;
    margin-bottom: 40px;
  }
  .head,
  .body {
    width: 100%;
    max-width: 270px;
  }
}

