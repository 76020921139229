.title {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0 14px 0 8px !important;
  height: 40px !important;
  border-radius: 6px !important;
  cursor: pointer !important;
}

.arrow {
  margin-left: 15px;
}

.dropdown {
  border: none !important;
  max-width: inherit !important;
  padding: 0 !important;
  border-radius: 7px !important;
  overflow: hidden !important;
  user-select: none !important;
  background-color: var(--color-black-light) !important;
  width: fit-content !important;
  min-width: fit-content !important;

}

.item {
  border-radius: 0;
  max-width: unset !important;
  width: 100% !important;
  background-color: var(--color-black-light) !important;
  &:hover {
    background-color: var(--color-black-dark) !important;
  }
}

.label {
  margin-bottom: 5px;
  color: var(--color-grey);
}

.light {
  background-color: var(--color-black-light) !important;
}

.dark {
  background-color: var(--color-black-dark) !important;
}

.normal {
  height: 50px !important;
}

.small {
  height: 35px !important;
}
