.wrapper {
  max-width: 445px;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 18px 12px;
}

.header {
  font-weight: 700;
  text-align: center;
}

.text {
  margin: 20px 0;
  max-width: 400px;
  display: flex;
  text-align: center;
  margin-bottom: 30px;
}

.row {
  height: 25px;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  & > :nth-child(1) {
    width: 100px;
    padding-left: 5px;
  }
  & > :nth-child(2) {
    margin-left: 30px;
    width: 250px;
  }
}

.copy {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.blockEase {
  width: 100%;
  transition: 0.2s ease border;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}
.copyBlock {
  border: var(--color-green) 1px solid !important;
}

.submit {
  margin-top: 20px;
}
.check {
  color: var(--color-green);
}

.next {
  margin-top: 10px;
  display: flex;
  text-align: center;
  color: var(--color-grey);
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding: 12px;
  }
  .row {
    height: 25px;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    width: none;
    display: flex;
    & > :nth-child(1) {
      width: none;
      padding: 0px;
    }
    & > :nth-child(2) {
      margin-left: 0px;
      width: 200px;

    }
    & > :nth-child(3) {
      width: 100%;
      display: flex;
      margin-top: -40px;
      justify-content: flex-end;
    }
  }
}
