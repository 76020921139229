.content {
  overflow: hidden;
  width: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.options {
  display: flex;
  align-items: center;
  & > :nth-child(2) {
    margin-left: 20px;
  }

  .text {
    display: flex;
    flex-direction: column;
    & > :nth-child(1) {
      font-weight: 600;
    }
    & > :nth-child(2) {
      color: var(--color-grey);
    }
  }
}

.security {
  display: flex;
  justify-content: space-between;
  color: var(--color-grey);
  & > :nth-child(1) {
    width: 220px;
  }
  & > :nth-child(2) {
    width: 170px;
  }
  & > :nth-child(3) {
    width: 400px;
  }
  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .head {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
    }
  }
}

.browser h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 340px;
}

.status {
  width: fit-content;
  padding: 0px 13px;
  border-radius: 99999px;
  &_red {
    color: var(--color-red);
    background-color: var(--color-red-dark);
  }
  &_green {
    color: var(--color-green);
    background-color: var(--color-green-dark);
  }
}

.verified {
  display: flex;
  align-items: center;
  .color {
    height: 5px;
    width: 5px;
    margin-right: 10px;
    border-radius: 99999px;
    &_red {
      background-color: var(--color-red);
    }
    &_green {
      background-color: var(--color-green);
    }
  }
  width: fit-content;
  border-radius: 99999px;
  padding: 0px 13px;
  background-color: var(--color-black-light);
}

@media screen and (max-width: 1260px) {
  .security {
    flex-direction: column;
    & > :nth-child(1n) {
      margin-bottom: 20px;
    }
    & > :nth-child(3) {
      width: fit-content;
      margin-bottom: 0;
      word-wrap: break-word;
    }
  }

  .row {
    flex-direction: column;
    align-items: flex-start;
    .options {
      margin-bottom: 30px;
    }
  }
}
