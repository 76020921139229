.page {
}

.header {
  font-size: 30px;
  font-weight: bold;
}

.content {
  margin-top: 130px;
  display: flex;
  justify-content: space-between;
  & > :not(:first-child) {
    margin-left: 200px;
  }
}

.control {
    margin-top: 30px;
}

.form {
    margin-top: 50px;
    max-width: 500px;
    & > :nth-child(1n) {
        margin-bottom: 20px;
    }
}

.buy {
    margin-top: 40px;
}