.modal {
  padding: 32px 27px;
  width: 579px;
  min-height: 400px;
}

.title {
  margin-bottom: 23px;
}

.content {
}

.block {
  padding: 22px 23px;
  margin-bottom: 5px;
}

.row {
  display: flex;
  justify-content: space-between;
  .from {
    color: var(--color-grey);
  }
  .count {
    display: flex;
    .counter {
      color: var(--color-grey);
      margin-right: 10px;
    }
    .max {
      color: var(--color-primal);
    }
  }
}

.balance {
  margin-top: 14px;
  .count {
    color: var(--color-grey);
  }
}

.password {
  margin-top: 15px;
}

.info {
  margin-top: 15px;
  .options {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    .label {
      color: var(--color-grey);
    }
  }
}

.send {
  margin-top: 15px;
  .contenBtn {
    display: flex;
    align-items: center;
    .contentSVG {
      width: 60px;
    }
  }
}

.countInput {
  width: 100%;
  margin-left: -10px;
  margin-right: 20px;
}