.wrapper {
  width: 423px;
  height: fit-content;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
}

.header {
  margin-top: 15px;
  font-weight: 700;
  text-align: center;
}

.head {
  margin-top: 15px;
  display: flex;
  text-align: center;
  color: var(--color-grey);
}

.inputTG {
  width: 100%;
  min-height: 120px;
  margin-top: 15px;
}

.submit {
  margin-top: 20px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .form {
    padding: 0;
  }
}
