.gradientText {
  color: transparent;
  background: linear-gradient(
    90deg,
    var(--color-blue) 0%,
    var(--color-gradient) 78%,
    var(--color-gradient) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
}

/* Убедитесь, что файл App.css подключен */
.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.gradient {
  height: 70vh;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(to bottom, var(--color-black), rgba(0, 0, 0, 0));
}

/* Растянутое изображение */
.fullWidthImage {
  position: absolute;
  top: 90px;
  left: 0px;
  width: 100%;
  height: 90%;
  background-image: url('../../../public/images//vector.png');
  background-size: cover;
  background-position: center;
  z-index: 0;
  opacity: 0.3;
  /* Настройте прозрачность, если нужно */
}

/* Изображение по центру */
.centeredImage {
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  .wallet {
    margin-right: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.centeredImage img {
  max-width: 100%;
  margin-left: 20px;
  height: auto;
}

.title {
  margin-top: -20px;
  display: flex;
  text-align: center;
}

.options {
  margin: 20px auto;
  color: var(--color-grey);
  display: flex;
  text-align: center;
  max-width: 509px;
}

.logo {
  position: absolute;
  z-index: 2;
  top: 40px;
  left: 40px;
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .centeredImage {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .wallet {
        margin-right: 20px;
    }
    /* Настройте прозрачность, если нужно */
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 0px;
  }
}
