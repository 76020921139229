.modal {
  padding: 32px 27px;
  width: 579px;
  overflow: hidden;
}

.textInfo {
  color: var(--color-grey);
}

.address {
  margin-top: 20px;
  width: 100%;
  word-break: break-all;
}

.image {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  overflow: hidden;
  & > img {
    width: 110%;
    height: 110%;
    object-fit: cover;
  }
}

.buttons {
  display: flex;
  width: 100%;
  margin-top: 30px;
  & > :nth-child(1) {
    min-width: 331px !important;
    margin-right: 10px;
    & > :nth-child(1) {
      display: flex !important;
      justify-content: flex-start !important;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  color: var(--color-white) !important;
  & > :nth-child(2) {
    margin-left: 10px;
  }
}

.block {
  border: inherit solid 1px;
  transition: 0.3s border ease;
  margin: 10px 0;
  padding: 22px 23px;
}

.select {
  margin: 10px 0;
}

.borderCopy {
  border: var(--color-green) solid 1px !important;
}

.qrCode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  overflow: hidden;
  flex-direction: column;
}

.selectes {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) and (max-width: 1260px) {
  .selectes {
    flex-direction: row;
    & > :nth-child(1) {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .modal {
    padding: 10px;
    width: 100%;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    & > :nth-child(1) {
      margin-bottom: 10px;
      min-width: 100px !important;
    }
  }
  .selectes {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .textInfo {
    display: flex;
    align-items: center;
    text-align: center;
  }
}
