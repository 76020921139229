.modal {
  padding: 32px 27px;
  width: 371px;
}

.imageWallet {
  margin-top: 9px;
  height: 209px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.importBtn {
  margin-top: 20px;
}

.textArea {
  margin-top: 10px;
  min-height: 141px;
}